<template>
 <div class="m-5">
  <b-card title="Venue" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
       

        <addcon @loaddata="loaddata"></addcon>
        <editcon @loaddata="loaddata" ref="modal"></editcon>
    
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(image)="data">
        <template>
          <img width="120px" :src="data.item.image" />
        </template>
      </template>
      <template #cell(detail)="data">
        <template>
           <div v-html="data.item.detail"></div>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
         
          <b-dropdown-item @click="edit_data(data.item.id)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="delete_data(data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
 </div>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import api from "@/api";
import addcon from "./Add.vue";
import editcon from "./Edit.vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    addcon,
    editcon,

  },
  data() {
    return {
      isAddCampaign: false,
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "name", label: "Packages" },
        { key: "image",label: "รูป" },
        { key: "price", label: "ราคาเต็ม" },
        { key: "discount", label: "ส่วนลด" },
        { key: "selling_price", label: "ราคาขาย" },

        { key: "detail", label: "รายละเอียด" },
        { key: "actions" },
      ],
      /* eslint-disable global-require */
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {

      api
        .get("/packages")
        .then((response) => {
          this.items = response.data.packages;
          this.totalRows = response.data.total;
          console.log(response.data);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },
    delete_data(id) {
      console.log("delete", id);
      const params = {
        id: id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.post("/packages_del", params).then((response) => {
            console.log(response.data);
            this.loaddata();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Your file has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    edit_data(id) {
      this.$refs.modal.showModaledit(id);
    },
 
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
